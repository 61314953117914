<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrbsNow()"
                 label-width="120px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="材料编号:">
                <el-input size="small" v-model.trim="searchForm.mtrb_no" clearable placeholder="请填写材料编号">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="材料名称:">
                <el-input size="small" v-model.trim="searchForm.mtrb_name" clearable placeholder="请填写材料名称">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="材料类型:">
                <el-select clearable size="small" v-model="searchForm.mtrb_type" placeholder="请选择材料类型" >
                  <el-option v-for="item in mtrbTypeList" :key="item.id" :label="item.label"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商简称:">
                <el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商简称">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="录入人:" prop="stff_id">
                <el-select filterable clearable v-model="searchForm.stff_id" placeholder="请选择录入人" size="small">
                  <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name"
                             :value="item.stff_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="录入时间:" style="display: flex;align-items: center">
                <div style="display: flex">
                  <el-date-picker size="small"
                                  v-model="searchForm.timeValue"
                                  type="datetimerange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"/>
                  <el-button size="small"
                             type="primary"
                             icon="el-icon-search"
                             @click="getMtrbsNow()"
                             style="margin-left: 15px">查询</el-button>
                  <el-button type="info"
                             size="small"
                             @click="buttonRefresh()">
                    <i class="el-icon-refresh-right"></i>刷新</el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增
        </el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i>
          删除</el-button> -->
          
        <!-- <el-button
          type="info"
          size="small"
          @click="copyRow"
          ><i class="el-icon-document-copy"></i> 复制</el-button
        > -->
      </div>
      <el-row>
        <el-col :span="24">
          <el-table class="vg_cursor" ref="multiTable" :data="tableData" v-loading="loadFlag" @row-dblclick="dbClickJp" border>
            <!-- <el-table-column type="selection" width="48" align="center" /> @selection-change="handleSelectionChange"
                    @select="selectRows" @select-all="selectRows" -->
            <el-table-column label="材料编号" prop="mtrb_no" :show-overflow-tooltip="true" min-width="100"/>
            <el-table-column label="材料类别" min-width="75" :show-overflow-tooltip="true" prop="mtrb_type" :formatter="formatterName" />
            <el-table-column label="材料名称" min-width="75" :show-overflow-tooltip="true" prop="mtrb_name">
            </el-table-column>
            <el-table-column label="供应商简称" prop="supp_abbr" :show-overflow-tooltip='true' />
            <el-table-column label="增值税率" prop="mtrb_inrate" :show-overflow-tooltip='true' :formatter="formatterInrate" />
            <el-table-column label="数量单位" prop="mtrb_unit" :show-overflow-tooltip='true'/>
            <el-table-column label="采购价" align="right" prop="mtrb_price" min-width="100" :formatter="formatterPrice"  />
            <el-table-column label="销售价" align="right" prop="mtrb_price" min-width="100" :formatter="formatterPrice" />
            <el-table-column label="录入人" prop="stff_name" :show-overflow-tooltip='true' />
            <el-table-column label="录入时间" prop="create_time" min-width="140" :show-overflow-tooltip='true'>
              <template slot-scope="scope">
                <span v-if="scope.row.create_time">
                  {{ scope.row.create_time | formatDate }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination">
          </pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { mtrbAPI } from "@api/modules/mtrb"
import { stffAPI } from "@api/modules/staff"
import { custAPI } from "@api/modules/cust"
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js"
import { suppAPI } from "../../../api/modules/supp";
export default {
  name: 'MtrbList',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        mtrb_no: null,
        mtrb_type: null,
        mtrb_name: null,
        supp_abbr: null,
        mtrb_ids: null,
        status: null,
        stff_id: null,
        timeValue: {
          startTime: null,
          endTime: null
        },//创建时间
      },
      loadFlag: true,
      mtrbStffList: [],
      custList: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      statusList: [{
        id: 0, label: '草拟'
      }, {
        id: 1, label: '在批'
      }, {
        id: 2, label: '生效'
      },],
      mtrbTypeList:[
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
      ],
      suppList:[]
    }
  },
  created() {
    if(this.$route.query.mtrb_ids){
      this.searchForm.mtrb_ids = this.$route.query.mtrb_ids
    }
    this.initData()
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mtrb_add' || from.path === '/mtrb_edit') {
        this.initData()
      }
    },
  },
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row)
    },
    formatDate1(row) {
      return helper.toTimeDay(row)
    },
  },
  methods: {
    initData() {
      this.getMtrbs();
      // this.getSupp()
      this.getStffUser()
    },
    // 获取公司抬头信息
    getMtrbs() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(mtrbAPI.getMtrbs,
          {
            mtrb_no: this.searchForm.mtrb_no,
            mtrb_ids: this.searchForm.mtrb_ids,
            mtrb_type: this.searchForm.mtrb_type,
            mtrb_name: this.searchForm.mtrb_name,
            supp_abbr: this.searchForm.supp_abbr,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            page_no: this.currentPage,
            status: this.searchForm.status,
            stff_id:this.searchForm.stff_id
          })
          .then(res => {
            if (res.data.code === 0) {
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              // this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          })
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1).then(res => {
        if (res.data.code === 0) {
          this.suppList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    // 查询方法
    getMtrbsNow() {
      this.loadFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.getMtrbs()
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mtrb_no: null,
        mtrb_ids: null,
        mtrb_type: null,
        mtrb_name: null,
        supp_abbr: null,
        status: null,
        stff_id: null,
        timeValue: {
          startTime: null,
          endTime: null
        },//创建时间
      };
      this.loadFlag = true
      this.currentPage = 1
      this.initData()
      this.$refs.pubPagination.currentPage = 1
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true
      this.currentPage = val
      this.getMtrbs()
    },
    // 材料类型给结果
    formatterName(val){
      if(val.mtrb_type === 1){
        return '辅料'
      }else if(val.mtrb_type === 2){
        return '包材'
      }
    },
    // 税率返回值
    formatterInrate(val){
      return this.helper.haveFour(val.mtrb_inrate)
    },
    // 采购价返回值
    formatterPrice(val){
      return this.helper.haveFour(val.mtrb_price)
    },
    // 多选获取公司抬头信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 新增
    addRow() {
      const permId = this.$route.query.perm_id
      this.jump(`/mtrb_add?perm_id=${permId}`)
    },
    // 复制
    copyRow(){
      if(this.multiSelection.length !== 1){
        return this.$message.warning('只能选择一条')
      }
      post(mtrbAPI.copyMtrb, { mtrb_id: this.multiSelection[0].mtrb_id })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.initData()
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData()
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData()
        })
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' })
      } else {
        this.mBox()
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow() {
      const ids = []
      this.multiSelection.map((item) => {
        ids.push(item.mtrb_id)
      })
      post(mtrbAPI.deleteMtrbByIds, { mtrb_id_list: ids })
          .then(res => {
            let mg = res.data.msg;
            if (res.data.code === 0) {
              let tp = 'success';
              this.$message({ message: mg, type: tp });
              if (ids.length === this.tableData.length) {
                if (this.currentPage > 1) {
                  this.currentPage = this.currentPage - 1
                  this.$refs.pubPagination.currentPage = this.currentPage
                }
              }
              this.initData()
            } else {
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData()
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData()
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id
      this.jump('/mtrb_edit',{perm_id:permId,form_id:row.mtrb_id})
      // const permId = this.$route.query.perm_id
      // this.jump('/mtrb_edit', {
      //   key: this.$UrlEncode.encode(JSON.stringify({
      //     perm_id: permId,
      //     form_id: row.mtrb_id
      //   }))
      // })
    },
    //获取录取人信息
    getStffUser() {
      get(custAPI.getAllStffs).then(res => {
        if (res.data.code === 0) {
          this.stffUserList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },

  }
};
</script>

<style scoped lang="scss">
</style>