import topic from '../topic'
export const custAPI = {
    getCusts: topic.custTopic + '/get_custs',
    getCustById: topic.custTopic + '/get_cust_by_id',
    addCust: topic.custTopic + '/add_cust',
    editCust: topic.custTopic + '/edit_cust',
    deleteCustByIds: topic.custTopic + '/delete_cust_by_ids',
    getAllCustsV1: topic.custTopic + '/get_all_custs_v1',
    getCustFromErp: '/api/open/api/get_cust_from_erp',
    getAllCustsV2: topic.custTopic + '/get_all_custs_v2',
    getMiddleCusts: topic.custTopic + '/get_middle_custs',
    getAllStffs: topic.custTopic + '/get_all_stffs',
    getAllDepts: topic.custTopic + '/get_all_depts',
}