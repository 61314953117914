import topic from '../topic';
export const suppAPI = {
  getSupps: topic.suppTopic + '/get_supps',
  getSuppAll: topic.suppTopic + '/get_suppAll',
  getSuppById: topic.suppTopic + '/get_supp_by_id',
  addSupp: topic.suppTopic + '/add_supp',
  editSupp: topic.suppTopic + '/edit_supp',
  deleteSuppByIds: topic.suppTopic + '/delete_supp_by_ids',
  getSuppsV1: topic.suppTopic + '/get_supps_v1',
  getDyjSuppsV1: topic.suppTopic + '/get_dyj_supps_v1',
  getSuppByBeloDeptId: topic.suppTopic + '/get_supp_by_belo_dept_id'
};
