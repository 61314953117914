import topic from '../topic'
export const mtrbAPI = {
    getMtrbs:topic.mtrbTopic + '/get_mtrbs',
    addMtrb:topic.mtrbTopic + '/add_mtrb',
    getMtrbById:topic.mtrbTopic + '/get_mtrb_by_id',
    editMtrb:topic.mtrbTopic + '/edit_mtrb',
    deleteMtrbByIds: topic.mtrbTopic + '/delete_mtrb_by_ids',
    getAllMtrbsV1: topic.mtrbTopic + '/get_all_mtrbs_v1',
    copyMtrb: topic.mtrbTopic + '/copy_mtrb',
    getAllMtrbsV2: topic.mtrbTopic + '/get_all_mtrbs_v2',
}